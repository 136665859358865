/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    img: "img"
  }, _provideComponents(), props.components), {Media} = _components;
  if (!Media) _missingMdxReference("Media", true);
  return React.createElement(React.Fragment, null, React.createElement(Media, {
    name: "黑蜂哥",
    avatar: "https://sdfbee-resource-1303933394.cos.ap-beijing.myqcloud.com/heifengge.jpg",
    content: "黑龙江神顶峰黑蜂产品有限公司总经理",
    link: "https://www.toutiao.com/article/7097482554688717324/?app=news_article&timestamp=1665576345&use_new_style=1&req_id=202210122005450101511802280752F9FC&group_id=7097482554688717324&wxshare_count=1&tt_from=weixin&utm_source=weixin&utm_medium=toutiao_android&utm_campaign=client_share&share_token=7c4f56ef-b4bf-4117-9f8b-ff97ee914bbf&source=m_redirect&wid=1665580112510"
  }), "\n", React.createElement(_components.p, null, "每年的 6、7 月份，东北黑蜂一年最忙的季节，完达山脉满山遍野椴花香，一道林区独特的蜂景，就是黑蜂采集的椴树花蜜。"), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "https://sdfbee-resource-1303933394.cos.ap-beijing.myqcloud.com/c2d94d11597c41c4b7f45bb5e78faa4d_noop.jpg",
    alt: ""
  })), "\n", React.createElement(_components.p, null, "完达山脉主峰神顶峰，海拔 831 米，是祖国东北边陲第一峰，也是中国诸多可以早早迎来日出的地方之一，日出，云海，松涛被誉为神顶峰“三绝”。神顶峰是闻名遐迩的旅游胜地，也是二十年的中国蜂业著名品牌，黑龙江省著名商标。"), "\n", React.createElement(_components.p, null, "2019 年，黑龙江神顶峰黑蜂产品有限公司开启了黑蜂产业文旅融合的转型升级，发起和承办了中国（虎林）国际蜂业论坛暨第一届椴树花蜜节，还邀请了德国，法国，日本及国内的蜂业大咖，汇聚黑龙江省虎林市，大会围绕“打造世界的蜂情小镇和共建中国的雪蜜之乡”展开研讨。"), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "https://sdfbee-resource-1303933394.cos.ap-beijing.myqcloud.com/119b146c30c244f59a42aed8f0ddf2b7_noop.jpg",
    alt: ""
  })), "\n", React.createElement(_components.p, null, "作为省级农业产业化重点龙头企业，神顶峰黑蜂公司大胆探索，于 2021 年打响乡村振兴的第一枪，向蜂业文旅转型升级，投资近 500 万元，收购和改造了虎头镇虎头要塞景区对面的一处闲置绿博园，更名为神顶峰蜂情园。改造后的蜂情园占地面积 34 亩，有红松原木大柱制作的大门楼子，俄罗斯蜂情小吃一条街，马克西姆俄餐厅，木屋民宿餐厅，蜂情山货美食绿色长廊，黑蜂蜜源植物博物馆，虎林特色优质农产品展厅，林下养蜂工厂和动物养殖观光区等。"), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "https://sdfbee-resource-1303933394.cos.ap-beijing.myqcloud.com/9fce987fa435432694bd4c3afda22e90_noop.jpg",
    alt: ""
  })), "\n", React.createElement(_components.p, null, "四根大柱高高举起的门楼，是园区最有气势和高度的建筑主体，立于中央位置，古朴大气，顶天立地。"), "\n", React.createElement(_components.p, null, "2021 年 7 月在虎头神顶峰蜂情园，又开始举办第二届中国（虎林）椴树花蜜节活动，这是神顶峰黑蜂公司的有一次创举，一次转型升级的自我突破。七月的林海黑蜂正忙，甜蜜的日子蜂收在望，成千上万的黑蜂和满山遍野的山花，共同采集酿造着自然椴蜜花香，引来了央视开拍《一年只采半月蜜》的神顶峰探蜜之旅，引来了中国蜂业专家学者和四方游客相聚虎头蜂情园。"), "\n", React.createElement(_components.p, null, "由虎林市委、虎林市政府主办，黑龙江神顶峰黑蜂产品有限公司承办的 2021 第二届中国（虎林）椴树花蜜节和神顶峰蜂情园开园仪式，于 7 月 18 日在虎林市 5 A 级景区举行。"), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "https://sdfbee-resource-1303933394.cos.ap-beijing.myqcloud.com/3ea1abee79f142989400bdd9e41b9fe9_noop.jpg",
    alt: ""
  })), "\n", React.createElement(_components.p, null, "第二届中国（虎林）椴树花蜜节，撬动黑蜂产业，实现蜂旅融合 ，神顶峰黑蜂+蜂情园文旅+椴花节开幕式的产业创新模式，引起了黑龙江省农业农村厅一级巡视员孙文志等领导和专家的高度关注，更得到虎林市委市政府领导的高度重视。黑龙江省农业农村厅领导孙文志和虎林市委书记高云禄，黑龙江省蚕蜂业发展中心主任高清，虎林市副市长徐太民等领导参加开幕式，并为蜂情园开园揭幕。域内外蜂业企业、蜂农和部分商贸企业的代表 200 多人参加开幕式活动，大会嘉宾为第二届中国（虎林）椴花节开幕式剪彩。"), "\n", React.createElement(_components.p, null, "虎林市神顶峰蜂情园位于虎林市虎头镇虎头要塞南侧，优良的自然生态环境，丰富的场景在现，黑蜂成熟蜜示范和加工体验，挖掘出黑蜂文化的魅力，三个月时间吸引和接待 2 多万游客，预计正常年份可接待游客超过 10 万人。"), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "https://sdfbee-resource-1303933394.cos.ap-beijing.myqcloud.com/84b1267779674519bdcb34082165c9c8_noop.jpg",
    alt: ""
  })), "\n", React.createElement(_components.p, null, "“小蜜蜂大产业，三产融合是方向；养蜂人想致富，跳出蜜箱看世界！”这是世界养蜂大会蜂蜜金奖得主，蜂情园园主，神顶峰雪蜜品牌创始人战立新的对未来蜂产业发展的认知，他说，神顶峰世界金奖蜂蜜，就是虎林的城市名片。"), "\n", React.createElement(_components.p, null, "打造北纬 45 度黄金蜜源带，树立“世界的好蜜在中国，中国的好蜜在虎林”的产业地标，这就是神顶峰雪蜜品牌下一个奋斗目标。"), "\n", React.createElement(_components.p, null, "黑龙江省农业农村厅一级巡视员孙文志在视察蜂情园时，非常认可虎林市神顶峰蜂情园项目建设的成果，称此行是神顶峰甜蜜之旅。希望虎林市积极推动蜂业合作，共同打造虎林椴树蜜地域品牌，推动虎林甜蜜事业结出丰硕果实。"), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "https://sdfbee-resource-1303933394.cos.ap-beijing.myqcloud.com/fa5b111d9a14483294a6af21c51e3219_noop.jpg",
    alt: ""
  })), "\n", React.createElement(_components.p, null, "虎林市虎头镇神顶峰蜂情园，自开园以来，举办了多场次农产品展销，网红打卡，直播带货，妇联，文联，餐饮协会等文旅活动，成为虎林市新的热点景区，引来的省内外游客不断增多，旅游公司也开始洽谈对接。从虎头神顶峰蜂情园开始引流，初步形成一条新的旅游精品线路，即到虎林市虎头镇神顶峰蜂情园第一站----神顶峰看日出和基地蜂场第二站----东方红镇神顶峰黑蜂产业科技园区第三站三天两夜蜂情文旅体验游。"), "\n", React.createElement(_components.p, null, "背景："), "\n", React.createElement(_components.p, null, "虎林市地处完达山南麓、东北黑蜂国家级自然保护区核心地带，生态环境优良，是全国首批国家生态文明建设示范市、中国黑蜂之乡、黑龙江省绿色养蜂基地和中国椴树蜂蜜的主要产区之一，境内有 200 多种蜜源植物，是天然的蜜源黄金地。目前，市域内蜜蜂饲养量达到 3.8 万群，养蜂场达到 500 多家，养蜂专业合作社 16 家，现有蜂产品经营、加工企业 21 家，占全省 13.1%，养蜂生产和蜂产品加工总产值近亿元。８家企业被中国绿色食品发展中心认定为绿色食品蜂蜜。“神顶峰”雪蜜更是走出国门，捧得国际养蜂大会金奖。"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
